<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['payPalCases_Case',])"></div>
      <CardLeftBlock
              :name="''"
              :value="''"
              :backgroundImage="'product-admin'"
      >
        <template slot="button">
          <h2 class="mt-0">{{$t('payPalCases_Case.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">

            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.financePayPalCases" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_createCase',])"></div>
              {{$t('payPalCases_createCase.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_pleaseFill',])"></div>
              {{$t('payPalCases_pleaseFill.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
<!--            <div class="custom-row" v-if="isAdmin">-->
<!--              <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100">-->
<!--                <UserSelect-->
<!--                    :validationUser="PC.data.User.validation.userId"-->
<!--                    :validationTranslateUser="PC.data.User.validationTranslate.userId"-->
<!--                    :userSelected="PC.data.User.user"-->
<!--                    :typeSelect="'users'"-->
<!--                    :userEmail="true"-->
<!--                    @changeUser="changeUser"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="custom-row" v-if="isAdmin">-->
<!--              <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100">-->
<!--                <UserSelect-->
<!--                    :validationUser="PC.data.AdminUser.validation.userId"-->
<!--                    :validationTranslateUser="PC.data.AdminUser.validationTranslate.userId"-->
<!--                    :userSelected="PC.data.AdminUser.user"-->
<!--                    :typeSelect="'users'"-->
<!--                    :userEmail="true"-->
<!--                    :label="'common_userAdmin'"-->
<!--                    @changeUser="changeAdminUser"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
            
            <FieldsBlock
                :PC="PC"
                :createData="createData"
                :createPageFlag="true"
            />
          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.financePayPalCases"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save', false, 'open')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import FieldsBlock from "../../chunks/FieldsBlock";
  // import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";


  export default {
    name: "PayPalCasesCreationSection",

    components: {
      // UserSelect,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      FieldsBlock,
    },

    props: {
      PC: Object,
      createData: Object,
    },

    data() {
      return {

      }
    },

    methods: {

      changeUser(val) {
        this.PC.data.User.setUser(val)
      },

      changeAdminUser(val){
        this.PC.data.AdminUser.setUser(val)
      },

    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

</style>
