<template>
	<div class="order-create__section">

		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
				<div class="order-create__table block-table">
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'consolidationMeest_date',
						])"></div>
								{{$t('consolidationMeest_date.localization_value.value')}}
							</div>
							<div class="block-table__content">
                {{ CM.data.Order.getCreatedAt() | moment("DD MMM, YYYY") }}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'consolidationMeest_trackingNumber',
						])"></div>
								{{$t('consolidationMeest_trackingNumber.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<ValueHelper
                    :value="CM.data.trackingNumber"
                />
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'consolidationMeest_sentDate',
						])"></div>
								{{$t('consolidationMeest_sentDate.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<ValueHelper
                    :value="CM.data.sentDate"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
							</div>
						</div>
					</div>
          <div class="block-table__row">
            <div class="block-table__col">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
							'consolidationMeest_comment',
						])"></div>
                {{$t('consolidationMeest_comment.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                <ValueHelper
                    :value="CM.data.comment"
                />
              </div>
            </div>
          </div>

          <div class="order-create__row custom-row">
            <div class="custom-col custom-col--66 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
            'consolidationMeest_Id',
            'common_user',
            'consolidationMeest_returnedPackageID',
            ])"></div>
              <div class="site-table-wrap table-small"
                   v-if="!isMobileFunc()"
              >
                <table class="site-table">
                  <thead>
                  <tr>
                    <th>{{$t('consolidationMeest_Id.localization_value.value')}}</th>
                    <th>{{$t('common_user.localization_value.value')}}</th>
                    <th>{{$t('consolidationMeest_returnedPackageID.localization_value.value')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in CM.data.meestArray" :key="index">
                    <td>
                      <div class="table-row font-weight-bold">
                        <router-link
                            class="table-link btn-style"
                            :to="$store.getters.GET_PATHS.ordersMeestReturns + '/show/' + item.id">
                          #{{item.id}}
                        </router-link>
                      </div>
                    </td>
                    <td>
                      <ValueHelper
                          :value="item"
                          :deep="'user.user_personal_contact.user_full_name'"
                      />
                    </td>
                    <td>
                      <router-link
                          v-if="item.return_parcel_id"
                          class="table-link btn-style"
                          :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + item.return_parcel_id">
                       {{item.return_parcel_id}}
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>

              <div class="site-table-mobile"
                   v-if="isMobileFunc()"
              >
                <div class="site-table-mobile__inner custom-row">
                  <div class="custom-col custom-col--50 custom-col--sm-100"
                       v-for="(item, index) in CM.data.meestArray" :key="index">
                    <div class="site-table-mobile__item">
                      <div class="site-table-mobile__head">
                        <router-link
                            class="table-link btn-style"
                            :to="$store.getters.GET_PATHS.ordersMeestReturns + '/show/' + item.id">
                          #{{item.id}}
                        </router-link>
                      </div>
                      <div class="site-table-mobile__row custom-row">

                        <div class="site-table-mobile__piece custom-col ">
                          {{$t('common_user.localization_value.value')}}
                          <span>
                            <ValueHelper
                                :value="item"
                                :deep="'user.user_personal_contact.user_full_name'"
                            />
                          </span>
                        </div>
                      </div>
                      <div class="site-table-mobile__row custom-row">

                        <div class="site-table-mobile__piece custom-col ">
                          {{$t('consolidationMeest_returnedPackageID.localization_value.value')}}
                          <span>
                           <router-link
                               class="table-link btn-style"
                               :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + item.return_parcel_id">
                             {{item.return_parcel_id}}
                            </router-link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>


  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
  export default {
		name: "ConfirmationConsolidationMeest",

		components: {
      ValueHelper
		},

    mixins: [mixinDetictingMobile],

		props: {
      CM: Object,
		},

		data() {
			return {

			}
		},

		methods: {

		}
	}
</script>

<style scoped>

</style>
