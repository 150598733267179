<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_consolidationMeest',])"></div>
      <CardLeftBlock
              :name="$t('menu_consolidationMeest.localization_value.value')"
              :value="'#' + CM.data.Order.getId()"
              :backgroundImage="'product-admin'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">

            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
<!--                <router-link :to="$store.getters.GET_PATHS.reportsConsolidationMeest" class="order-create__head-back">-->
                  <div class="order-create__head-back">
                    <LinkBack
                            :value="$t('ex_backTo.localization_value.value')"
                            @click.native="goRouterBack($store.getters.GET_PATHS.reportsConsolidationMeest)"
                    />
                  </div>
<!--                </router-link>-->
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['consolidationMeest_consolidationMeest',])"></div>
              {{$t('consolidationMeest_consolidationMeest.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['consolidationMeest_pleaseFill',])"></div>
              {{$t('consolidationMeest_pleaseFill.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            
            <FieldsBlock
                :CM="CM"
            />

          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.reportsConsolidationMeest"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_save.localization_value.value')"
                      @click.native="$emit('save', true, 'open')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import FieldsBlock from "../../chunks/FieldsBlock";


  export default {
    name: "ConsolidationMeestEditingSection",

    components: {
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      FieldsBlock,
    },

    props: {
      CM: Object,
    },

    data() {
      return {

      }
    },

    methods: {

    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

</style>
