<template>
  <ConsolidationMeestCreationSection
          :CM="CM"
          @save="save"
  />
</template>

<script>
  import ConsolidationMeestCreationSection from "./ConsolidationMeestCreationSection/ConsolidationMeestCreationSection.vue";
  import {ConsolidationMeest} from "../../models/ConsolidationMeest";
  import {consolidationMeestMixin} from "@/mixins/consolidationMeestMixins/consolidationMeestMixin";

  export default {
    name: "ConsolidationMeestCreation",

    components:{
      ConsolidationMeestCreationSection,
    },

    mixins: [consolidationMeestMixin],

    data() {
      return {
        CM: new ConsolidationMeest(),
      }
    },

    mounted() {

    },

    methods: {

    }

  }
</script>

<style scoped>

</style>
