import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";
import _ from "lodash";
import {CONSOLIDATION_MEEST_STATUSES, CONSOLIDATION_MEEST_STATUSES_ARRAY} from "@/staticData/staticVariables";
// import moment from 'moment';

export function ConsolidationMeest() {

    this.checkValid = checkValid

    this.data = {
        Order: new Order(),
        comment: '',
        sentDate: '',
        trackingNumber: '',
        meestArray: [],
    }

    this.validation = {
        comment: false,
        sentDate: false,
        trackingNumber: false,
        meestArray: false,
    }

    this.validationTranslate = {
        comment: '',
        sentDate: '',
        trackingNumber: '',
        meestArray: '',
    }

    this.validationTxt = {
        comment: false,
        sentDate: false,
        trackingNumber: false,
        meestArray: false,
    }

    /**
     * Getters
     */
    this.getComment = () => {
        return this.data.comment
    }
    this.getSentDate = () => {
        return this.data.sentDate
    }
    this.getTrackingNumber = () => {
        return this.data.trackingNumber
    }
    this.getMeestArray = () => {
        return this.data.meestArray
    }

    /**
     * Setters
     */
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setSentDate = (val) => {
        this.data.sentDate = val
    }
    this.setTrackingNumber = (val) => {
        this.data.trackingNumber = val
    }
    this.setMeestArray = (val) => {
        this.data.meestArray = val
    }
}

/**
 * Global Setters
 */

ConsolidationMeest.prototype.setItem = function (item) {
    // this.setShippedDate(item.delivery_date ? item.delivery_date : '')
    this.setComment(item.comment ? item.comment : '')
    this.setTrackingNumber(item.tracking_number ? item.tracking_number : '')
    this.setSentDate(item.sent_at ? item.sent_at : '')
    this.data.Order.setStatus(item.status ? _.find(CONSOLIDATION_MEEST_STATUSES_ARRAY, {value: item.status}) : CONSOLIDATION_MEEST_STATUSES.CONSOLIDATION_MEEST_STATUS_IN_PROGRESS)

    this.data.Order.setCreatedAt(item.created_at ? item.created_at : '')

    item.return_documents.map(meest => {
        this.data.meestArray.push(meest)
    })
}

/**
 * Prepare Data
 */

ConsolidationMeest.prototype.prepareSave = function () {
    return {
        'comment': this.getComment(),
        // 'delivery_date': moment(this.getShippedDate()).format('YYYY-MM-DD'),
        'tracking_number': this.getTrackingNumber(),
        'return_document_ids': this.getMeestArray().map(item => {return item.id}),
        'status': this.data.Order.getStatus()?.value,
    }
}

/**
 * Validations
 */

ConsolidationMeest.prototype.firstValidation = function () {

    let validationItems = {
        trackingNumber: this.getTrackingNumber(),
        meestArray: this.getMeestArray(),
    }

    let validationOptions = {
        trackingNumber: {type: ['empty']},
        meestArray: {type: ['empty-array']},
    }

    return (this.checkValid(validationItems, validationOptions))
}
