<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['apcDelcon_scanNumber'])"></div>
        <SearchInput
            class="scan-ico"
            :label="$t('apcDelcon_scanNumber.localization_value.value')"
            v-model="scanValue"
            @submit="scan($event)"
            @keyup.native="scan($event, true)"
        />
        <div class="color-red pt-2"
             v-if="scanErrorItem !== ''"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['apcDelcon_notFound'])"></div>
          {{"'" + scanErrorItem + "'"}} {{$t('apcDelcon_notFound.localization_value.value')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import SearchInput from "../../../../UI/inputs/SearchInput/SearchInput";

export default {
  name: "ScannerBlock",
  components: {
    SearchInput,
    // DefaultInput
  },

  props: {
    CM: Object,
  },

  data() {
    return {
      scanValue: '',
      scanErrorItem: '',
    }
  },

  methods: {
    scan(e, checkPressedKey = false) {

      if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      if(this.scanValue.length === 0) return

      this.scanErrorItem = ''
      let queryString = `?filter[ReturnDocumentId]=${this.scanValue}`

      this.$store.dispatch('fetchReturnGoodsDocuments', queryString).then((response) => {

        let respData = this.getRespPaginateData(response)


        if (respData.length) {
          let foundItem = respData[0]

          this.CM.data.meestArray.push(foundItem)
        }
        else {
          this.scanErrorItem = this.scanValue
        }

        this.scanValue = ''

      }).catch(error => this.createErrorLog(error))
    }
  },
}
</script>

<style scoped>

</style>