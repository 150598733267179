<template>
  <div class="fragment">


    <ScannerBlock
        :CM="CM"
    />


    <div class="custom-row" v-if="isAdmin">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidationMeest_meests',
          ])"></div>

        <MultiSelect
            class="white"
            :options="optionsMeests"
            :label="$t('consolidationMeest_meests.localization_value.value')"
            v-bind:class="{'ui-no-valid': CM.validation.meestArray}"
            :error="CM.validation.meestArray"
            :errorTxt="$t(`${CM.validationTranslate.meestArray}.localization_value.value`)"
            :otherValue="'consolidationMeest'"
            :selected="CM.data.meestArray"
            :searchable="true"
            @change="changeMeests"
        />
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
        <DefaultInput
            :label="$t('consolidationMeest_trackingNumber.localization_value.value')"
            :type="'text'"
            :disabled="isObject(CM.data.trackingNumberObject)"
            v-bind:class="{'ui-no-valid': CM.validation.trackingNumber}"
            :errorTxt="$t(`${CM.validationTranslate.trackingNumber}.localization_value.value`)"
            :error="CM.validation.trackingNumber"
            v-model="CM.data.trackingNumber"
        />
      </div>
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_comment'])"></div>
        <TextareaDefault
            :label="$t('consolidationMeest_comment.localization_value.value')"
            v-bind:class="{'ui-no-valid': CM.validation.comment}"
            :errorTxt="$t(`${CM.validationTranslate.comment}.localization_value.value`)"
            :error="CM.validation.comment"
            v-model="CM.data.comment"
        />
        <!--            :placeholder="'Please be careful with the vase inside!'"-->
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidationMeest_status'])"></div>
        <div class="section-label">
          <DefaultSelect
              :options="CONSOLIDATION_MEEST_STATUSES_ARRAY"
              :label="$t('consolidationMeest_status.localization_value.value')"
              :otherValue="'translation'"
              :selected="CM.data.Order.data.status"
              :clearable="false"
              @change="changeStatus"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
// import DatePicker from 'vue2-datepicker'
// import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
// import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
import {filterFunc} from "@/mixins/filterFuncMixin/filterFunc";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
// import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import MultiSelect from "@/components/UI/selectiones/MultiSelect/MultiSelect";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
import ScannerBlock from "@/components/modules/ConsolidationMeestModule/components/chunks/ScannerBlock";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import {CONSOLIDATION_MEEST_STATUSES_ARRAY} from "@/staticData/staticVariables";

export default {
  name: "FieldsBlock",
  components: {
    DefaultSelect,
    ScannerBlock,
    MultiSelect,
    // DefaultSelect,
    // SearchSelect,
    TextareaDefault,
    DefaultInput,
    // UserSelect,
    // DatePickerDefault,
    // DatePicker,
  },

  mixins: [filterFunc, returnGoodsMixin, routeFilter],

  props: {
    CM: Object,
  },

  data() {
    return {
      optionsMeests: [],

      CONSOLIDATION_MEEST_STATUSES_ARRAY: CONSOLIDATION_MEEST_STATUSES_ARRAY,
    }
  },

  mounted() {
    let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

    myQuery.where('byExistingDeliveryService', false)

    let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)

    this.$store.dispatch('fetchReturnGoodsDocuments', url).then((response) => {
      this.optionsMeests = this.getRespPaginateData(response)

      if (this.$route.query.id) {
        this.$route.query.id.split(',').map(id => {
          let foundMeest = this._.find(this.optionsMeests, {id: parseInt(id)})

          if (foundMeest) {
            this.CM.data.meestArray.push(foundMeest)
          }
        })
      }
    })
  },

  methods: {

    changeMeests(val) {
      this.CM.data.meestArray = val
    },

    changeUser(val) {
      this.CM.data.User.setUser(val)
    },

    changeStatus(val) {
      this.CM.data.Order.setStatus(val)
    },
  },

}
</script>

<style scoped>

</style>