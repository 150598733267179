<template>
  <PayPalCasesCreationSection
          :PC="PC"
          :createData="createData"
          @save="save"
  />
</template>

<script>
  import PayPalCasesCreationSection from "./PayPalCasesCreationSection/PayPalCasesCreationSection.vue";
  import {PayPalCases} from "../../models/PayPalCases";
  import {payPalCasesMixin} from "../../../../../mixins/payPalCasesMixins/payPalCasesMixin";

  export default {
    name: "PayPalCasesCreation",

    components:{
      PayPalCasesCreationSection,
    },

    mixins: [payPalCasesMixin],

    // watch: {
    //   loadUserAuthorizedData: function () {
    //     this.initPayPalCases()
    //   },
    // },

    data() {
      return {
        PC: new PayPalCases({files: 5}),
        createData: null,
      }
    },

    mounted() {
      this.initPayPalCasesCreate()

      // if(this.loadUserAuthorizedData){
      //   this.initPayPalCases()
      // }
    },

    methods: {

      // initPayPalCases() {
      //   if(this.isAdmin){
      //     this.PC.data.AdminUser.setUser(this.$store.getters.getUserProfile)
      //   }
      //
      //   if (!this.isAdmin) {
      //     this.PC.data.User.setUser(this.loadUserAuthorizedData?.user)
      //   }
      // },

      initPayPalCasesCreate() {
        this.$store.dispatch('getPayPalCasesCreate').then(response => {
          this.createData = this.getRespData(response)
        })
      },

    }

  }
</script>

<style scoped>

</style>
