<template>
  <ConsolidationMeestShowSection
          :CM="CM"
          @save="save"
  />
</template>

<script>
  import {ConsolidationMeest} from "../../models/ConsolidationMeest";
  import ConsolidationMeestShowSection from "./ConsolidationMeestShowSection/ConsolidationMeestShowSection";
  import {consolidationMeestMixin} from "@/mixins/consolidationMeestMixins/consolidationMeestMixin";

  export default {
    name: "ConsolidationMeestShow",

    components:{
      ConsolidationMeestShowSection,
    },

    mixins: [consolidationMeestMixin],

    data() {
      return {
        CM: new ConsolidationMeest(),
        transactionUser: {},
      }
    },

    mounted() {
      this.initConsolidationMeestEdit()
    },

    methods: {

      initConsolidationMeestEdit() {
        this.CM.data.Order.setId(this.$route.params.id)

        this.$store.dispatch('getConsolidationMeest', this.CM.data.Order.getId()).then((response) => {
          let item = this.getRespData(response)
          this.CM.setItem(item, this)
        }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
